import React, {useState, useEffect, useRef} from 'react'
import {Link, useParams, useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {TreeSelect} from 'antd';
import {XCircle, Save} from 'react-feather';

import {POST, GET, FILE, PUT} from '../../../api/adminApi'

import '../../../css/antd.css';
import {Button} from "react-bootstrap";

const TreeNode = TreeSelect.TreeNode;

function AttrValuesAddUpdate() {
	let {id} = useParams();
	const newId = +id;
	const inputRef = useRef();
	const inputRefIcon = useRef();
	let history = useHistory();
	const [data, setData] = useState({
		imageUrl: '',
		iconImageUrl: ''
	})
	const [categories, setCategories] = useState([{name: 'Нет', categoryId: 0, id: 0}])
	const {register, handleSubmit, formState: {errors}, setValue} = useForm()
	const [category, setCategory] = useState()
	const [show, setShow] = useState(false)
	
	const onChange = (string) => {
		setCategory(string)
		// setValue('attributeId', string)
	};
	
	function sortCategories(arr) {
		var tree = [{name: 'Нет', id: 0, children: []}],
			treeChild = []
		for (var i = 0, len = arr.length; i < len; i++) {
			if (arr[i].parentId === 0) {
				arr[i]['children'] = []
				tree.push(arr[i]);
			} else {
				// arr[i]['children'] = []
				treeChild.push(arr[i])
			}
		}
		for (let i = 0; i < treeChild.length; i++) {
			for (let j = 0; j < treeChild.length; j++) {
				if (treeChild[i].id === treeChild[j].parentId) {
					treeChild[i]['children'] = []
				}
			}
		}
		
		for (let i = 0; i < treeChild.length; i++) {
			for (let j = 0; j < treeChild.length; j++) {
				if (treeChild[i].id === treeChild[j].parentId) {
					treeChild[i]['children'].push(treeChild[j])
				}
			}
		}
		
		for (let i = 0; i < tree.length; i++) {
			for (let j = 0; j < treeChild.length; j++) {
				if (tree[i]['id'] === treeChild[j]['parentId']) {
					tree[i]['children'].push(treeChild[j])
				}
			}
		}
		setCategories(tree)
		setShow(true)
		return tree;
	}
	
	async function getCategories() {
		const categories = await GET('/services/admin/api/categories-helper')
		sortCategories(categories)
	}
	
	async function create(data) {
		var response;
		if (history.location.pathname.includes('update')) {
			response = await PUT('/services/admin/api/attribute-value', data)
		} else {
			response = await POST('/services/admin/api/attribute-value', data)
		}
		if (response) {
			history.push('/admin/attribute/values' + category)
		}
		
	}
	
	async function getProduct() {
		const response = await GET(`/services/admin/api/attribute-value/${isNaN(newId) ? 1 : newId}`)
		for (let key in response) {
			setValue(`${key}`, response[key])
		}
		
		onChange(response.categoryId)
		setData({...data, imageUrl: response.imageUrl, iconImageUrl: response.iconImageUrl})
	}
	
	async function getImageUrl(e) {
		if (e.target.files[0]) {
			const image = e.target.files[0];
			const formData = new FormData();
			formData.append('image', image);
			const response = await FILE("/services/admin/api/categories-image-upload", formData);
			setData({...data, imageUrl: response.url})
			setValue('imageUrl', response.url)
		}
	}
	
	async function getIconImageUrl(e) {
		if (e.target.files[0]) {
			const image = e.target.files[0];
			const formData = new FormData();
			formData.append('image', image);
			const response = await FILE("/services/admin/api/categories-image-upload", formData);
			setData({...data, iconImageUrl: response.url})
			setValue('iconImageUrl', response.url)
		}
	}
	
	useEffect(() => {
		// getCategories()
		if (history.location.pathname.includes('update')) {
			getProduct()
		}
		if (history.location.pathname.includes('create') && isNaN(newId) === false) {
			setValue('attributeId', newId)
		}
	}, [id]) // eslint-disable-line react-hooks/exhaustive-deps
	
	return (
		<div className="mb-2 mx-3 ">
			<h3 className="mb-3 mt-1">Добавить новый</h3>
			<div className="admin-card p-4 overflow-y-auto" style={{height: "80vh"}}>
				<form onSubmit={handleSubmit(create)} action="" autoComplete='off'>
					<div className="row">
						<div className='col-md-6 mb-3'>
							<div className='admin-field'>
								<div className='d-flex w-100'>
									<div className='admin-label mr-md-3'><label className='mb-0'>Название (UZ)</label>
									</div>
									<input {...register('nameUz', { required: true })} type='text'
									       className='input-control' />
								</div>
								{errors.nameUz?.type === 'required' &&
									<div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							<div className='admin-field'>
								<div className='d-flex w-100'>
									<div className='admin-label mr-md-3'><label className='mb-0'>Название (RU)</label>
									</div>
									<input {...register('nameRu', { required: true })} type='text'
									       className='input-control' />
								</div>
								{errors.nameRu?.type === 'required' &&
									<div className='text-danger mt-1 fz12'>Обязательное поле</div>}
							</div>
							{/*<div className='admin-field'>*/}
							{/*	<div className='d-flex w-100'>*/}
							{/*		<div className='admin-label mr-md-3'><label className='mb-0'>Тип</label>*/}
							{/*		</div>*/}
							{/*		<input {...register('type')} type='text'*/}
							{/*		       className='input-control' />*/}
							{/*	</div>*/}
							{/*	{errors.type?.type === 'required' &&*/}
							{/*		<div className='text-danger mt-1 fz12'>Обязательное поле</div>}*/}
							{/*</div>*/}
							{/*<div className="admin-field">*/}
							{/*	<div className="d-flex w-100">*/}
							{/*		<div className="admin-label mr-md-3"><label className="mb-0">Родительская*/}
							{/*			Кат.</label></div>*/}
							{/*		<div className="w-100 position-relative">*/}
							{/*			<TreeSelect*/}
							{/*				style={{width: '100%'}}*/}
							{/*				value={category}*/}
							{/*				dropdownStyle={{maxHeight: 400, overflow: 'auto'}}*/}
							{/*				placeholder="Please select"*/}
							{/*				treeDefaultExpandAll*/}
							{/*				onChange={onChange}*/}
							{/*				showSearch={false}*/}
							{/*			>*/}
							{/*				{show &&*/}
							{/*					categories.map((item, index) => {*/}
							{/*						return (*/}
							{/*							<TreeNode key={item.id} value={item.id} title={item.name}>*/}
							{/*								{*/}
							{/*									item.children.length &&*/}
							{/*									item.children.map((item2, index2) => {*/}
							{/*										return (*/}
							{/*											<TreeNode key={item2.id} value={item2.id}*/}
							{/*											          title={item2.name}></TreeNode>*/}
							{/*										)*/}
							{/*									})*/}
							{/*								}*/}
							{/*							</TreeNode>*/}
							{/*						)*/}
							{/*					})*/}
							{/*				}*/}
							{/*			</TreeSelect>*/}
							{/*		</div>*/}
							{/*	</div>*/}
							{/*</div>*/}
							{/*<div className="admin-field d-flex">*/}
							{/*	<div className="d-flex w-100">*/}
							{/*		<div className='admin-label'><label className="mb-0">Главная фото</label></div>*/}
							{/*		<div className='input-control border-none'>*/}
							{/*			<div className="position-relative">*/}
							{/*				<div onClick={() => inputRef.current.click()} className="image-block"*/}
							{/*				     style={{*/}
							{/*					     width: '100%',*/}
							{/*					     maxWidth: '300px',*/}
							{/*					     height: '200px',*/}
							{/*					     objectFit: "contain",*/}
							{/*					     zIndex: 1*/}
							{/*				     }}>Перетащите сюда файлы для загрузки*/}
							{/*				</div>*/}
							{/*				<input style={{*/}
							{/*					width: '100%',*/}
							{/*					maxWidth: '300px',*/}
							{/*					height: '200px', position: "absolute"*/}
							{/*				}} ref={inputRef} onChange={(e) => getImageUrl(e)} type="file"*/}
							{/*				       className="image-input"/>*/}
							{/*				{data.imageUrl &&*/}
							{/*					<div className="position-absolute cursor border-radius-3" style={{*/}
							{/*						width: '100%',*/}
							{/*						maxWidth: '300px',*/}
							{/*						height: '200px',*/}
							{/*						top: 0,*/}
							{/*						zIndex: 2*/}
							{/*					}}>*/}
							{/*						<img*/}
							{/*							style={{objectFit: "contain"}}*/}
							{/*							className="w-100 h-100 background-white border box-shadow admin-image"*/}
							{/*							src={'https://bozormedia.uz' + (data.imageUrl)} alt=""/>*/}
							{/*					</div>*/}
							{/*				}*/}
							{/*			</div>*/}
							{/*		</div>*/}
							{/*	</div>*/}
							{/*</div>*/}
						</div>
						{/*<div className="col-md-6 mb-3">*/}
						{/*	<div className="admin-field">*/}
						{/*		<div className="d-flex w-100">*/}
						{/*			<div className="admin-label mr-md-3"><label className="mb-0">Описание (UZ)</label>*/}
						{/*			</div>*/}
						{/*			<textarea {...register("descriptionUz")} type="text"*/}
						{/*			          className="input-control"/>*/}
						{/*		</div>*/}
						{/*		{errors.descriptionUz?.type === 'required' &&*/}
						{/*			<div className='text-danger mt-1 fz12'>Обязательное поле</div>}*/}
						{/*	</div>*/}
						{/*	<div className="admin-field">*/}
						{/*		<div className="d-flex w-100">*/}
						{/*			<div className="admin-label mr-md-3"><label className="mb-0">Описание (RU)</label>*/}
						{/*			</div>*/}
						{/*			<textarea {...register("descriptionRu")} type="text"*/}
						{/*			          className="input-control"/>*/}
						{/*		</div>*/}
						{/*		{errors.descriptionRu?.type === 'required' &&*/}
						{/*			<div className='text-danger mt-1 fz12'>Обязательное поле</div>}*/}
						{/*	</div>*/}
						{/*	<div className="admin-field">*/}
						{/*		<div className="d-flex w-100">*/}
						{/*			<div className="admin-label mr-md-3"><label className="mb-0">Описание (EN)</label>*/}
						{/*			</div>*/}
						{/*			<textarea {...register("descriptionEn")} type="text"*/}
						{/*			          className="input-control"/>*/}
						{/*		</div>*/}
						{/*		{errors.descriptionUz?.type === 'required' &&*/}
						{/*			<div className='text-danger mt-1 fz12'>Обязательное поле</div>}*/}
						{/*	</div>*/}
						{/*	<div className="admin-field d-flex">*/}
						{/*		<div className="d-flex w-100">*/}
						{/*			<div className='admin-label'><label className="mb-0">Иконка</label></div>*/}
						{/*			<div className='input-control border-none'>*/}
						{/*				<div className="position-relative">*/}
						{/*					<div onClick={() => inputRefIcon.current.click()} className="image-block"*/}
						{/*					     style={{*/}
						{/*						     width: '100%',*/}
						{/*						     maxWidth: '300px',*/}
						{/*						     height: '200px',*/}
						{/*						     objectFit: "contain",*/}
						{/*						     zIndex: 1*/}
						{/*					     }}>Перетащите сюда файлы для загрузки*/}
						{/*					</div>*/}
						{/*					<input style={{*/}
						{/*						width: '100%',*/}
						{/*						maxWidth: '300px',*/}
						{/*						height: '200px', position: "absolute"*/}
						{/*					}} ref={inputRefIcon} onChange={(e) => getIconImageUrl(e)} type="file"*/}
						{/*					       className="image-input"/>*/}
						{/*					{data.iconImageUrl &&*/}
						{/*						<div className="position-absolute cursor border-radius-3" style={{*/}
						{/*							width: '100%',*/}
						{/*							maxWidth: '300px',*/}
						{/*							height: '200px',*/}
						{/*							top: 0,*/}
						{/*							zIndex: 2*/}
						{/*						}}>*/}
						{/*							<img*/}
						{/*								className="w-100 h-100 background-white border box-shadow admin-image"*/}
						{/*								style={{objectFit: "contain"}}*/}
						{/*								src={'https://bozormedia.uz' + (data.iconImageUrl)} alt=""/>*/}
						{/*						</div>*/}
						{/*					}*/}
						{/*				</div>*/}
						{/*			</div>*/}
						{/*		</div>*/}
						{/*	</div>*/}
						{/*</div>*/}
					</div>
					<div className="btn-postion d-flex">
						<Link to='/admin/categories' className="mr-3"><Button
							className="btn bg-dark d-flex align-items-center"><XCircle size={14} className="mr-2"/>Отмена
						</Button></Link>
						<Button type='submit' className="btn bg-primary d-flex align-items-center"><Save size={14}
						                                                                                 className="mr-2"/>Сохранить
						</Button>
					</div>
				</form>
			</div>
		</div>
	)
}

export default AttrValuesAddUpdate
