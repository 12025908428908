import {HashRouter as Router, Switch, Route} from "react-router-dom";
import {useSelector} from "react-redux";
import {ToastContainer} from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Attributes from './components/admin/Attributes/Attributes'
import AttributesAddUpdate from './components/admin/Attributes/AttributesAddUpdate'
import AttrValues from './components/admin/AttrValues/AttrValues'
import AttrValuesAddUpdate from './components/admin/AttrValues/AttrValuesAddUpdate'

import Header from "./components/front/Header";
import Footer from "./components/front/Footer";
import Checkout from "./components/front/Checkout";
import Detail from "./components/front/Detail";
import Basket from "./components/front/Basket";
import Profile from "./components/front/Profile";

// Admin
import SideBar from "./components/admin/SideBar";
import AdminLogin from "./components/admin/Login";
import Bazar from "./components/admin/Bazars/Bazar";
import BazarAddUpdate from "./components/admin/Bazars/BazarAddUpdate";

import Territory from "./components/admin/Territories/Territory";


import Categories from "./components/admin/Categories/Categories";
import CategoriesAddUpdate from "./components/admin/Categories/CategoriesAddUpdate";



// CSS
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "react-select2-wrapper/css/select2.css";
import "./css/bootstrap.min.css";
import "./css/bootstrap.css";
import "./css/App.css";

import {
    PrivateRoute,
    AdminPrivateRoute,
} from "./private";
import {store} from "./store";
import OptionsCrud from "./components/admin/Options/OptionsCrud";
import Options from "./components/admin/Options/Options";
import BazarOptions from "./components/admin/BazarOptions/BazarOptions";
import BazarOptionsCrud from "./components/admin/BazarOptions/BazarOptionsCrud";
import BazarCategories from "./components/admin/BazarCategory/BazarCategories";
import BazarCategoriesCrud from "./components/admin/BazarCategory/BazarCategoriesCrud";
import Shop from "./components/admin/Shop/Shop";
import ShopCrud from "./components/admin/Shop/ShopCrud";
import Products from "./components/admin/Products/Products";
import ProductsCrud from "./components/admin/Products/ProductsCrud";
import ShopOptions from "./components/admin/shopOptions/ShopOptions";
import ShopOptionsCrud from "./components/admin/shopOptions/ShopOptionsCrud";
import ShopCategoriesCrud from "./components/admin/shopCategories/ShopCategoriesCrud";
import ShopProductsCrud from "./components/admin/shopProducts/ShopProductsCrud";
import ShopCategories from "./components/admin/shopCategories/ShopCategories";
import ShopProducts from "./components/admin/shopProducts/ShopProducts";
import BazarShops from "./components/admin/BazarShops/BazarShops";
import SellerList from "./components/admin/Sellers/SellerList";
import UserList from "./components/admin/Users/UserList";

function App() {
    const loader = useSelector((state) => state.loader);
    const modal = useSelector((state) => state.modal);
    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={false}
                closeOnClick={false}
                // hideProgressBar={false}
                // newestOnTop={false}
            />
            {loader && (
                <div id="loading-bg">
                    <div className="loading">
                        <div className="spinner-border text-purple"></div>
                    </div>
                </div>
            )}
            {modal && (
                <Modal
                    hide={() => store.dispa}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Очистить корзину?
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            В нашей корзине есть блюда из другого ресторана. Они будут
                            удалены для добавления новых.
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <div>
                            <btn className="btn btn-purple">Отменить</btn>
                            <btn className="btn btn-purple">Продолжить</btn>
                        </div>
                    </Modal.Footer>
                </Modal>
            )}
            <Router>
                <Switch>
                    {/* ADMIN */}

                    <Route path="/admin/login" component={AdminLogin}/>

                    <Route path="/admin/:path?" exact>
                        <SideBar>
                            <Switch>
                                <AdminPrivateRoute
                                    path="/admin/shop"
                                    exact
                                    component={Shop}
                                />
                                <AdminPrivateRoute
                                    path="/admin/options"
                                    exact
                                    component={Options}
                                />
                                <AdminPrivateRoute
                                    path="/admin/bazar"
                                    exact
                                    component={Bazar}
                                />
                                <AdminPrivateRoute
                                    path="/admin/products"
                                    exact
                                    component={Products}
                                />
                                <AdminPrivateRoute
                                    path="/admin/territory"
                                    exact
                                    component={Territory}
                                />
                                <AdminPrivateRoute
                                    path="/admin/categories"
                                    component={Categories}
                                />
                                <AdminPrivateRoute
                                    path="/admin/seller"
                                    component={SellerList}
                                />
                                <AdminPrivateRoute
                                    path="/admin/user"
                                    component={UserList}
                                />
                            </Switch>
                        </SideBar>
                    </Route>
                    <Route path="/admin/:path/:path" exact>
                        <SideBar>
                            <Switch>

                                <AdminPrivateRoute
                                    path="/admin/bazar/options"
                                    exact
                                    component={BazarOptions}
                                />
                                <AdminPrivateRoute
                                    path="/admin/shop/options"
                                    exact
                                    component={ShopOptions}
                                />
                                <AdminPrivateRoute
                                    path="/admin/shop/category"
                                    exact
                                    component={ShopCategories}
                                />
                                <AdminPrivateRoute
                                    path="/admin/shop/products"
                                    exact
                                    component={ShopProducts}
                                />
                                <AdminPrivateRoute
                                    path="/admin/product/create"
                                    exact
                                    component={ProductsCrud}
                                />
                                <AdminPrivateRoute
                                    path="/admin/shop/create"
                                    exact
                                    component={ShopCrud}
                                />
                                <AdminPrivateRoute
                                    path="/admin/options/create"
                                    exact
                                    component={OptionsCrud}
                                />
                                <AdminPrivateRoute
                                    path="/admin/bazar/category"
                                    exact
                                    component={BazarCategories}
                                />
                                <AdminPrivateRoute
                                  path="/admin/category/:id"
                                  component={Attributes}
                                />
                                <AdminPrivateRoute
                                    path="/admin/bazar/create"
                                    component={BazarAddUpdate}
                                />
                            </Switch>
                        </SideBar>
                    </Route>
                    <Route path="/admin/:path/:path/:path?" exact>
                        <SideBar>
                            <Switch>
                                <AdminPrivateRoute
                                    path="/admin/bazar/shops/:id"
                                    exact
                                    component={BazarShops}
                                />
                                <AdminPrivateRoute
                                    path="/admin/product/update/:id"
                                    exact
                                    component={ProductsCrud}
                                />
                                <AdminPrivateRoute
                                    path="/admin/bazar/category/create"
                                    exact
                                    component={BazarCategoriesCrud}
                                />
                                <AdminPrivateRoute
                                    path="/admin/shop/update/:id"
                                    exact
                                    component={ShopCrud}
                                />
                                <AdminPrivateRoute
                                    path="/admin/options/update/:id"
                                    exact
                                    component={OptionsCrud}
                                />
                                <AdminPrivateRoute
                                    path="/admin/bazar/update/:id"
                                    component={BazarAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/directory/categories/create"
                                    component={CategoriesAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/bazar/options/create"
                                    component={BazarOptionsCrud}
                                />
                                <AdminPrivateRoute
                                    path="/admin/shop/options/create"
                                    component={ShopOptionsCrud}
                                />
                                <AdminPrivateRoute
                                    path="/admin/shop/category/create"
                                    component={ShopCategoriesCrud}
                                />
                                <AdminPrivateRoute
                                    path="/admin/shop/products/create"
                                    component={ShopProductsCrud}
                                />
                                <AdminPrivateRoute
                                  path="/admin/attribute/update/:id"
                                  component={AttributesAddUpdate}
                                />
                                <AdminPrivateRoute
                                  path="/admin/attribute/create/:id"
                                  component={AttributesAddUpdate}
                                />
                                <AdminPrivateRoute
                                  path="/admin/attribute/values/:id"
                                  component={AttrValues}
                                />
                            </Switch>
                        </SideBar>
                    </Route>

                    <Route path="/admin/:path/:path/:path/:id" exact>
                        <SideBar>
                            <Switch>
                                <AdminPrivateRoute
                                    path="/admin/bazar/options/update/:id"
                                    component={BazarOptionsCrud}
                                />
                                <AdminPrivateRoute
                                    path="/admin/shop/options/update/:id"
                                    component={ShopOptionsCrud}
                                />
                                <AdminPrivateRoute
                                    path="/admin/shop/categories/update/:id"
                                    component={ShopCategoriesCrud}
                                />
                                <AdminPrivateRoute
                                    path="/admin/shop/products/update/:id"
                                    component={ShopProductsCrud}
                                />
                                <AdminPrivateRoute
                                    path="/admin/directory/categories/update/:id"
                                    component={CategoriesAddUpdate}
                                />
                                <AdminPrivateRoute
                                  path="/admin/attribute/values/create/:id"
                                  component={AttrValuesAddUpdate}
                                />
                                <AdminPrivateRoute
                                  path="/admin/attribute/values/update/:id"
                                  component={AttrValuesAddUpdate}
                                />
                            </Switch>
                        </SideBar>
                    </Route>

                    {/* OPERATOR */}

                    <Route path="/operator/:path?" exact>

                    </Route>

                    {/* WEB */}

                    <Route path="/:path?" exact>
                        <PrivateRoute path="/checkout" component={Checkout}/>
                        <Route path="/" exact component={AdminLogin}/>
                        <Route path="/basket" exact component={Basket}/>
                        <PrivateRoute path="/profile" exact component={Profile}/>
                    </Route>

                    <Route path="/:path/:path?" exact>
                        <div className="d-flex flex-column min-vh-100">
                            <Header/>
                            <div style={{marginTop: "75px"}}>
                                <Route path="/detail/:id" exact component={Detail}/>
                            </div>

                            <div className="mt-auto">
                                <Footer/>
                            </div>
                        </div>
                    </Route>
                </Switch>
            </Router>
        </>
    );
}

export default App;
